var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[(_vm.error)?_c('CAlert',{attrs:{"color":"danger"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_c('CCard',[_c('CCardHeader',{staticStyle:{"display":"flex","align-items":"center"}},[_vm._t("header",function(){return [_c('CIcon',{attrs:{"name":"cil-grid"}}),_vm._v(" IP Log ")]}),_c('div',{staticClass:"card-header-actions",staticStyle:{"margin-left":"auto"}},[_c('CButton',{attrs:{"color":"light"},on:{"click":function($event){$event.preventDefault();return _vm.loadLogItems(true)}}},[_c('CIcon',{staticStyle:{"margin-top":"-2px"},attrs:{"name":"cil-sync"}}),_vm._v(" Reload")],1)],1)],2),_c('CCardBody',[(!_vm.authKey)?_c('p',[_vm._v("Your subscription is inactive.")]):_c('CDataTable',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadLogItems),expression:"loadLogItems"}],attrs:{"itemsPerPage":15,"loading":_vm.isLoading,"border":"","items":_vm.getFormattedItems,"fields":_vm.columns,"infinite-scroll-disabled":"disableInfinity","infinite-scroll-distance":10},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('div',{staticClass:"text-center my-5"},[_c('h2',[_vm._v("No items")])])]},proxy:true},{key:"loading",fn:function(){return [_c('CElementCover',{attrs:{"boundaries":[
                    { sides: ['top'], query: 'td' },
                    { sides: ['bottom'], query: 'tbody' }
                  ]}},[_c('h1',{staticClass:"d-inline"},[_vm._v("Loading... ")]),_c('CSpinner',{attrs:{"size":"5xl","color":"info"}})],1)]},proxy:true},{key:"pattern-header",fn:function(){return [_vm._v(" Rule "),_c('span',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: 'The pattern created to process this request.',
                  placement: 'bottom'
                }),expression:"{\n                  content: 'The pattern created to process this request.',\n                  placement: 'bottom'\n                }"}],staticClass:"question-mark-icon"},[_vm._v("?")])]},proxy:true},{key:"ip",fn:function(ref){
                var item = ref.item;
return [_c('td',[_c('div',{staticClass:"flag-ip"},[_c('img',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:(_vm.getCountryNameByCode(item.country_code)),expression:"getCountryNameByCode(item.country_code)"}],attrs:{"src":'/flags/'+item.country_code+'.png'}}),_vm._v(" "+_vm._s(item.ip))])])]}},{key:"result",fn:function(ref){
                var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.result)}},[_vm._v(_vm._s(item.result))])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }