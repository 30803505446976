<template>
  <div>
    <CRow>
      <CCol lg="12">

        <CAlert color="danger" v-if="error">
          {{ error }}
        </CAlert>
        <CCard>
          <CCardHeader style="display: flex; align-items: center">
            <slot name="header">
              <CIcon name="cil-grid"/> IP Log
            </slot>
            <div class="card-header-actions" style="margin-left: auto">
              <CButton color="light" @click.prevent="loadLogItems(true)"><CIcon style="margin-top: -2px;" name="cil-sync"/> Reload</CButton>
            </div>
          </CCardHeader>
          <CCardBody          >
            <p v-if="!authKey">Your subscription is inactive.</p>
            <CDataTable
                v-else
                :itemsPerPage="15"
                :loading="isLoading"
                border
                :items="getFormattedItems"
                :fields="columns"
                v-infinite-scroll="loadLogItems"
                infinite-scroll-disabled="disableInfinity"
                :infinite-scroll-distance="10"
            >

              <template #no-items-view>
                <div class="text-center my-5">
                  <h2>No items</h2>
                </div>
              </template>

              <template #loading>
                <CElementCover
                    :boundaries="[
                      { sides: ['top'], query: 'td' },
                      { sides: ['bottom'], query: 'tbody' }
                    ]"
                >
                  <h1 class="d-inline">Loading... </h1><CSpinner size="5xl" color="info"/>
                </CElementCover>
              </template>

              <template #pattern-header>
                Rule
                <span class="question-mark-icon" v-c-tooltip="{
                    content: 'The pattern created to process this request.',
                    placement: 'bottom'
                  }">?</span>
              </template>

              <template #ip="{item}">
                <td>
                  <div class="flag-ip"><img v-c-tooltip="getCountryNameByCode(item.country_code)" :src="'/flags/'+item.country_code+'.png'">&nbsp;{{item.ip}}</div>
                </td>
              </template>

              <template #result="{item}">
                <td>
                  <CBadge :color="getBadge(item.result)">{{item.result}}</CBadge>
                </td>
              </template>
            </CDataTable>

          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import countries from '../assets/countries.json';

export default {
  name: 'Logs',
  components: {},
  data() {
    return {
      isLoading: false,
      loadDataOffset: '',

      columns: [
        {key: 'created_at_formatted', label: 'Time'},
        {key: 'ip', label: 'IP'},
        {key: 'gateway', label: 'Gateway'},
        {key: 'login', label: 'Login'},
        {key: 'result', label: 'Status', _classes: 'text-bold'},
        {key: 'reason', label: 'Reason'},
        {key: 'pattern', label: 'Rule'},
        {key: 'attempts_left', label: 'Attempts Left'},
        {key: 'time_left', label: 'Lockout Duration'},
      ],
      authKey: null,
      error: '',
      logItems: [],
    }
  },
  methods: {
    loadLogItems(isReload = false) {

      if(!this.authKey) return;

      this.isLoading = true;

      if(isReload) {
        this.logItems = [];
      }

      axios
          .get(
              process.env.VUE_APP_CLOUD_API_URL + '/log',
              {
                headers: {
                  'X-Api-Key': this.authKey
                },
                params: {
                  limit: 25,
                  offset: !isReload ? this.loadDataOffset : ''
                }
              }
          )
          .then(response => {
            this.logItems = [
              ...this.logItems,
              ...response.data.items
            ];

            this.isLoading = false;
            this.loadDataOffset = response.data.offset;
          })
          .catch(error => {
            if (error.response) {

              this.error = error.response.status + ': ' + error.response.data.message;
            } else {
              console.log('Error', error.message);
            }

            this.isLoading = false;
          });
    },
    getBadge (result) {
      return result === 'allow' ? 'success'
              : result === 'pass' ? 'warning'
              : result === 'deny' ? 'danger' : 'primary'
    },
    getCountryNameByCode(code) {
      return countries.find(item => item.code === code).name || '-';
    }
  },
  computed: {
    getFormattedItems() {
      const fieldsForNA = ['attempts_left', 'time_left']
      return this.logItems.map(item => {

        item['created_at_formatted'] = moment.unix(item['created_at']).format('MMMM D, YYYY hh:mm a');

        for(let field in item) {
          if(!item[field]) {
            item[field] = fieldsForNA.includes(field) ? 'n/a' : '';
          }
        }
        return item;
      });
    },
    disableInfinity() {
      return !this.loadDataOffset;
    }
  },
  created() {

    if(this.$route.query.key) {
      this.authKey = this.$route.query.key;
    }
  },
  mounted() {
    this.loadLogItems()
  }
}
</script>
<style lang="scss">
.flag-ip {
  white-space: nowrap;
  display: flex;
  align-items: center;

  img {
    width: 20px;
    height: auto;
  }
}

.question-mark-icon {
  color: #888;
  display: inline-block;
  background-color: #ececec;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  text-align: center;
  font-size: 12px;
  cursor: default;
  float: right;
  line-height: 18px;
}
</style>